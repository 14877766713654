import Header from "../components/Header";
import React, {useEffect, useState} from "react";
import {UserPreferences} from "../components/UserPreferences";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import {protectedResources} from "../authConfig";
import UserClient from "../api/UserClient";
import {useAccount, useMsal} from "@azure/msal-react";
import EmailDetail from "../components/EmailDetail";
import {Email} from "../models/Email.ts";

const Emails = () => {

    const {instance, accounts, inProgress} = useMsal();
    const account = useAccount(accounts[0] || {});
    const [preferences, setPreferences] = useState([]);

    useEffect(() => {

        if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent({
                scopes: protectedResources.apiHello.scopes,
                account: account
            }).then((response) => {

                const client = new UserClient(response.accessToken);
                client.fetchMessageByUserId()
                    .then(response => {
                        setPreferences(response?.data)
                    });

            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({
                            scopes: protectedResources.apiHello.scopes,
                        }).then((response) => {
                            const client = new UserClient(response.accessToken);
                            client.fetchMessageByUserId(response.uniqueId)
                                .then(response => setPreferences(response?.data));
                        }).catch(error => console.log(error));
                    }
                }
            });
        }

    }, [account, inProgress, instance]);

    const email = new Email();

    return(
        <div>
            <Header title={'Emails'}/>
            <EmailDetail email={email}/>
        </div>
    );
};

export default Emails;
