import { BetterMailPreference } from './BetterMailPreference.ts';

export class Email{
    id:number | undefined;
    created:Date | undefined;
    emailAddress:string | undefined;
    isDefault:boolean | undefined;
    userId:string | undefined;
    preference:BetterMailPreference | undefined;
}
