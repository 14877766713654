import {Fragment, useEffect, useState} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {BanIcon, BellIcon, CheckCircleIcon, MenuIcon, XIcon} from '@heroicons/react/outline'

import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import {b2cPolicies, loginRequest} from "../authConfig";
import {useTranslation} from 'react-i18next';
import {Link, NavLink, useLocation} from "react-router-dom";

const lngs = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'Français' }
};

const navigation = [
    { name: 'Dashboard', href: 'dashboard', current: true },
    { name: 'Teams', href: 'teams', current: false },
    { name: 'Messages', href: 'messages', current: false },
    { name: 'Calendar', href: 'hello', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const NavigationBar = () => {

    let location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const { i18n } = useTranslation();

    const { instance } = useMsal();

    const [isDoorOpened, setDoorOpened] = useState(true)


    return (
        <Disclosure as="nav" className="bg-wbt-color">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block lg:hidden h-8 w-auto"
                                        src="/wbt-logo-square-white.svg"
                                        alt="WorkBetterTogeter"
                                    />
                                    <img
                                        className="hidden lg:block h-8 w-auto"
                                        src="/wbt-logo-text-white.svg"
                                        alt="WorkBetterTogeter"
                                    />
                                </div>
                                <AuthenticatedTemplate>
                                    <div className="hidden sm:block sm:ml-6">
                                        <div className="flex space-x-4">
                                            {navigation.map((item) => (
                                                <NavLink
                                                    to={item.href}
                                                    key={item.name}
                                                    className={classNames(
                                                        splitLocation[1] === item.href ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                        'px-3 py-2 rounded-md text-sm font-medium'
                                                    )}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                </AuthenticatedTemplate>
                            </div>
                            <AuthenticatedTemplate>
                                <div>
                                    {Object.keys(lngs).map((lng) => (
                                        <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                                            {lngs[lng].nativeName}
                                        </button>
                                    ))}
                                </div>

                                <div className="flex space-x-2 absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                    <button
                                        type="button"
                                        className="bg-wbt-color p-1 rounded-full text-gray-400 hover:text-white"
                                    >
                                        <span className="sr-only">My office door</span>
                                        {isDoorOpened ?
                                        <CheckCircleIcon className="h-6 w-6" aria-hidden="true" onClick={() => setDoorOpened(false)}/>:

                                        <BanIcon className="h-6 w-6" aria-hidden="true" onClick={() => setDoorOpened(true)}/>
                                         }
                                    </button>

                                <button
                                    type="button"
                                    className="bg-wbt-color p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                >
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>

                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="bg-wbt-color flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">Open user menu</span>
                                            <img
                                                className="h-8 w-8 rounded-full"
                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        onClick={() => instance.loginPopup(b2cPolicies.authorities.editProfile)}
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Your Profile
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <NavLink
                                                        to="settings"
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Settings
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Sign out
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                </div>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => instance.loginRedirect(loginRequest)}>
                                    Login
                                </button>
                            </UnauthenticatedTemplate>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={classNames(
                                        splitLocation[1] === item.href ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block px-3 py-2 rounded-md text-base font-medium'
                                    )}
                                    aria-current={splitLocation[1] === item.href ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default NavigationBar;
