import Config from "./Config";
import {callApiWithToken} from "../fetch";

class UserClient {
    accessToken
    constructor(accessToken) {
        this.accessToken = accessToken;
        this.config = new Config();
    }

    async fetchMessageByUserId() {
        console.log("Fetching messages for current user");

        return callApiWithToken(this.accessToken, this.config.USER_URL + "/messages")
            .then(([response, json]) => {
                console.log("Response JSON: " + JSON.stringify(json));
                if (!response.ok) {
                    return { success: false, error: json };
                }
                return { success: true, data: json };
            })
            .catch((e) => {
                this.handleError(e);
            });
    }


    async fetchTeamsByUserId(userId) {
        console.log("Fetching teams for Id: " + userId);

        return callApiWithToken(this.accessToken, this.config.USER_URL + "/" + userId + "/teams")
            .then(([response, json]) => {
                console.log("Response JSON: " + JSON.stringify(json));
                if (!response.ok) {
                    return { success: false, error: json };
                }
                return { success: true, data: json };
            })
            .catch((e) => {
                this.handleError(e);
            });
    }

    handleError(error) {
        const err = new Map([
            [TypeError, "There was a problem fetching the response."],
            [SyntaxError, "There was a problem parsing the response."],
            [Error, error.message],
        ]).get(error.constructor);
        console.log(err);
        return err;
    }
}

export default UserClient;
