import React, {useEffect, useState} from "react";
import {useAccount, useMsal} from "@azure/msal-react";
import {protectedResources} from "../authConfig";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import UserClient from "../api/UserClient";
import Header from "../components/Header";
import MessageList from "../components/MessageList";

const Messages = (props) => {

    const {instance, accounts, inProgress} = useMsal();
    const account = useAccount(accounts[0] || {});
    const [messages, setMessages] = useState([]);

    useEffect(() => {

        if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent({
                scopes: protectedResources.apiHello.scopes,
                account: account
            }).then((response) => {

                const client = new UserClient(response.accessToken);
                client.fetchMessageByUserId()
                    .then(response => {
                        setMessages(response?.data)
                    });

            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({
                            scopes: protectedResources.apiHello.scopes,
                        }).then((response) => {
                            const client = new UserClient(response.accessToken);
                            client.fetchMessageByUserId()
                                .then(response => setMessages(response.data));
                        }).catch(error => console.log(error));
                    }
                }
            });
        }

    }, [account, inProgress, instance]);

    return (
    <div>
        <Header title={props.title}/>
        <MessageList messages={messages}></MessageList>

    </div>
    );
};

export default Messages;
