import {protectedResources} from "../authConfig";

export const HelloData = (props) => {


    return (
        <>
            <div className="data-area-div">
                <p>Calling <strong>custom protected web API</strong>...</p>
                <ul>
                    <li><strong>endpoint:</strong> <mark>{protectedResources.apiHello.endpoint}</mark></li>
                    <li><strong>scope:</strong> <mark>{protectedResources.apiHello.scopes[0]}</mark></li>
                </ul>
                <p>Contents of the <strong>response</strong> is below:</p>
            </div>
            <div id="profile-div">
                <p><strong>First Name: </strong> {props.graphData.givenName}</p>
                <p><strong>Last Name: </strong> {props.graphData.surname}</p>
                <p><strong>Email: </strong> {props.graphData.userPrincipalName}</p>
                <p><strong>Id: </strong> {props.graphData.id}</p>
            </div>
        </>
    );
}
