import Header from "../components/Header";
import React from "react";

const Dashboard = () => {

    return (
        <div>
            <Header title={'Dashboard'}/>

        </div>
    );

};

export default Dashboard;
