import React, {useEffect, useRef, useState} from "react";
import {Formik, Field, Form, FormikHelpers, ErrorMessage, useFormikContext} from 'formik';
import TimezoneSelect from "react-timezone-select";
import {Trans, useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import { Switch } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';

const EmailDetail = (props) => {

    const [selectedTimezone, setSelectedTimezone] = useState({});

    const { t } = useTranslation();

    const WBTSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#0c1981',
            '&:hover': {
                backgroundColor: alpha( '#0c1981', theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor:  '#0c1981',
        },
    }));

    return (
        <>
            <div><pre>{
            JSON.stringify(props.email, null, 2) }</pre></div>

            <div className="mt-8 max-w-7xl mx-auto">
                <div className="shadow overflow-hidden sm:rounded-md">
                    <Formik
                        initialValues={{
                            workingDay_Mo: false,
                        }}
                        onSubmit={(
                            values: Values,
                            { setSubmitting }: FormikHelpers<Values>
                        ) => {
                            setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                                setSubmitting(false);
                            }, 500);
                        }}
                    >
                        {formik => (
                            <Form>

                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

                                    <div>
                                        <legend className="text-base font-medium text-gray-900"><Trans i18nKey="emailDetail.new"/></legend>
                                        <p className="text-sm text-gray-500"><Trans i18nKey="emailDetail.description"/></p>
                                    </div>

                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6">
                                            <label htmlFor="emailAddress" className="block text-sm font-medium text-gray-700">
                                                <Trans i18nKey="emailDetail.emailAddress"/>
                                            </label>
                                            <Field
                                                type="text"
                                                name="emailAddress"
                                                id="emailAddress"
                                                className="mt-1 focus:ring-wbt-color focus:border-wbt-color block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            <ErrorMessage className="error" component="div" name="emailAddress" />

                                        </div>
                                        <div className="col-span-6">
                                            <label htmlFor="timezone" className="block text-sm font-medium text-gray-700">
                                                <Trans i18nKey="emailDetail.timezone"/>
                                            </label>
                                            <TimezoneSelect
                                                value={selectedTimezone}
                                                onChange={setSelectedTimezone}
                                                name="timezone"
                                                id="timezone"
                                                className="sm:text-sm focus:border-wbt-color"
                                            />

                                            <ErrorMessage className="error" component="div" name="timezone" />

                                        </div>
                                        <div className="col-span-6">
                                            <div className="space-y-4">
                                                <div className="flex items-center">

                                                    <WBTSwitch
                                                        type="checkbox"
                                                        name="isDefault"
                                                        id="isDefault"
                                                        className="focus:ring-wbt-color h-4 w-4 text-wbt-color border-gray-300 rounded"
                                                    />
                                                    <label htmlFor="isDefault" className="ml-3 block text-sm font-medium text-gray-700">
                                                        <Trans i18nKey="emailDetail.isDefault"/>
                                                    </label>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <fieldset>
                                        <div>
                                            <legend className="text-base font-medium text-gray-900"><Trans i18nKey="emailDetail.preferences"/></legend>
                                            <p className="text-sm text-gray-500"><Trans i18nKey="emailDetail.preferences.description"/></p>
                                        </div>
                                        <div className="mt-4 space-y-4">
                                            <div className="flex items-center">
                                                <WBTSwitch
                                                    id="isOnsendFeedback"
                                                    name="isOnsendFeedback"
                                                    type="checkbox"
                                                    className="focus:ring-wbt-color h-4 w-4 text-wbt-color border-gray-300 rounded"
                                                />
                                                <label htmlFor="isOnsendFeedback" className="ml-3 block text-sm font-medium text-gray-700">
                                                    <Trans i18nKey="emailDetail.isOnsendFeedback"/>
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <WBTSwitch
                                                    id="isOnsendCheck"
                                                    name="isOnsendCheck"
                                                    type="checkbox"
                                                    className="focus:ring-wbt-color h-4 w-4 text-wbt-color border-gray-300 rounded"
                                                />
                                                <label htmlFor="isOnsendCheck" className="ml-3 block text-sm font-medium text-gray-700">
                                                    <Trans i18nKey="emailDetail.isOnsendCheck"/>
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <WBTSwitch
                                                    id="deliveryConstraints"
                                                    name="deliveryConstraints"
                                                    type="checkbox"
                                                    className="focus:ring-wbt-color h-4 w-4 text-wbt-color border-gray-300 rounded"
                                                    onChange={(event) => {formik.setFieldValue('deliveryConstraints', event.target.checked)}}
                                                />
                                                <label htmlFor="deliveryConstraints" className="ml-3 block text-sm font-medium text-gray-700">
                                                    <Trans i18nKey="emailDetail.deliveryConstraints"/>
                                                </label>
                                            </div>
                                            {formik.values.deliveryConstraints ?
                                                <>
                                                <div className="flex items-center">
                                                <TextField
                                                id="recieveNotBefore"
                                                label={t('emailDetail.recieveNotBefore')}
                                                type="time"
                                                variant="standard"
                                                defaultValue="08:00"
                                                InputLabelProps={{
                                                shrink: true,
                                            }}
                                                inputProps={{
                                                step: 900, // 15 min
                                            }}
                                                sx={{ width: 150 }}
                                                />
                                                <div className="ml-3 block">
                                                <TextField
                                                id="recieveNotAfter"
                                                label={t('emailDetail.recieveNotAfter')}
                                                type="time"
                                                variant="standard"
                                                defaultValue="17:00"
                                                InputLabelProps={{
                                                shrink: true,
                                            }}
                                                inputProps={{
                                                step: 900, // 15 min
                                            }}
                                                sx={{ width: 150 }}
                                                />
                                                </div>
                                                </div>
                                                    <div className="flex items-center">
                                                        <WBTSwitch
                                                            id="recieveMailAfterWork"
                                                            name="recieveMailAfterWork"
                                                            type="checkbox"
                                                            className="focus:ring-wbt-color h-4 w-4 text-wbt-color border-gray-300 rounded"
                                                            onChange={(event) => {formik.setFieldValue('recieveMailAfterWork', event.target.checked)}}
                                                        />
                                                        <label htmlFor="deliveryConstraints" className="ml-3 block text-sm font-medium text-gray-700">
                                                            <Trans i18nKey="emailDetail.recieveMailAfterWork"/>
                                                        </label>
                                                    </div>
                                                </>
                                                : <></>
                                            }

                                        </div>
                                    </fieldset>

                                </div>



                                <div className="space-x-2 px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-wbt-color hover:bg-wbt-color-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-wbt-color">Submit</button>
                                </div>
                            </Form>
                            )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default EmailDetail;
