import React, {Suspense, useEffect} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import {MsalProvider, useMsal} from "@azure/msal-react";
import {EventType, InteractionType} from "@azure/msal-browser";
import {b2cPolicies} from "./authConfig";

import {PageLayout} from "./components/PageLayout";
import {Hello} from "./pages/Hello";
import Messages from "./pages/Messages";
import Teams from "./pages/Teams";
import ErrorBoundary from "./error/ErrorBoundary";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Emails from "./pages/Emails";

const Pages = () => {

    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance } = useMsal();

    /**
     * Using the event API, you can register an event callback that will do something when an event is emitted.
     * When registering an event callback in a react component you will need to make sure you do 2 things.
     * 1) The callback is registered only once
     * 2) The callback is unregistered before the component unmounts.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
     */
    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
                    } else if (event.interactionType === InteractionType.Popup) {
                        instance.loginPopup(b2cPolicies.authorities.forgotPassword)
                            .catch(e => {
                                return;
                            });
                    }
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS){
                if(event.idTokenClaims['newUser']){
                    alert("NEW USER");
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                if (event?.payload) {
                    /**
                     * We need to reject id tokens that were not issued with the default sign-in policy.
                     * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                     * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                     */
                    if (event.payload.idTokenClaims["tfp"] === b2cPolicies.names.forgotPassword) {
                        window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
                        return instance.logout();
                    } else if (event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile) {
                        window.alert("Profile has been edited successfully. \nPlease sign-in again.");
                        return instance.logout();
                    }
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);

    return (
        <>
            <Routes>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="hello" element={<Hello />} />
                <Route path="teams" element={<Teams title={"Teams"}/>} />
                <Route path="" element={<Hello />} />
                <Route path="messages" element={<Messages title={"Messages"}/>}/>

                <Route path="settings" element={<Settings />} />
                <Route path="emails" element={<Emails />} />
            </Routes>
        </>
    )
}

// loading component for suspense fallback
const Loader = () => (
    <div className="flex items-center justify-center h-screen">

        <svg className="w-24 h-24 animate-spin text-wbt-color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.6 66.6">

                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1-2">
                        <path className="fill-current"
                              d="M37.2,24.5A17.68,17.68,0,0,1,19.7,9.3a3.54,3.54,0,0,0-3.4-2.4h0a3.61,3.61,0,0,0-3.6,3.7,2.2,2.2,0,0,0,.1.8,21.28,21.28,0,0,0,41.6,0,2,2,0,0,0,.1-.8A17.75,17.75,0,0,1,37.2,24.5Z"
                              transform="translate(-0.01 0)"/>
                        <path className="fill-current"
                              d="M50.8,6.9h0a3.73,3.73,0,0,0-3.6,2.9A14,14,0,0,1,33.5,20.9,13.93,13.93,0,0,1,19.9,9.7c0-.1-.1-.3-.1-.4a17.62,17.62,0,0,0,34.7,1.2,3.67,3.67,0,0,0-3.7-3.6Z"
                              transform="translate(-0.01 0)"/>
                        <circle className="fill-current" cx="33.59" cy="6.9" r="6.9"/>
                        <path className="fill-current"
                              d="M19.8,9.8A18,18,0,0,0,30.7,23.2a18.06,18.06,0,0,0,11.7.5A17.71,17.71,0,0,0,47.7,21a18,18,0,0,0,6.6-9.8,18,18,0,0,1-2.4,5.5,17.76,17.76,0,0,1-9.5,7.2,17.41,17.41,0,0,1-6,.8,17.65,17.65,0,0,1-11-4.4,15.42,15.42,0,0,1-3.7-4.7A21.87,21.87,0,0,1,19.8,9.8Z"
                              transform="translate(-0.01 0)"/>
                        <path className="fill-current"
                              d="M22.6,36.5a17.59,17.59,0,0,1-4.4,22.7,3.63,3.63,0,0,0-.4,4.2h0a3.69,3.69,0,0,0,5,1.3,2.65,2.65,0,0,0,.6-.5,21.25,21.25,0,0,0-20.8-36c-.2.1-.5.2-.7.3A17.9,17.9,0,0,1,22.6,36.5Z"
                              transform="translate(-0.01 0)"/>
                        <path className="fill-current"
                              d="M.5,33.6h0a3.58,3.58,0,0,0,4.2,1.7A14,14,0,0,1,18.4,59l-.3.3a17.48,17.48,0,0,0,4.4-22.7,17.73,17.73,0,0,0-20.7-8,3.69,3.69,0,0,0-1.3,5Z"
                              transform="translate(-0.01 0)"/>
                        <circle className="fill-current" cx="9.29" cy="48.6" r="6.9"/>
                        <path className="fill-current"
                              d="M18.5,59a17.86,17.86,0,0,0,6.2-16.1,16.68,16.68,0,0,0-1.9-5.7,15.12,15.12,0,0,0-3.6-4.7A17.76,17.76,0,0,0,2.4,28.4a17.34,17.34,0,0,1,6-.7,17.54,17.54,0,0,1,5.9,1.4,17.19,17.19,0,0,1,5.1,3.3,19.24,19.24,0,0,1,3.7,4.8A17.87,17.87,0,0,1,24.8,49a18.18,18.18,0,0,1-2.2,5.6A28.51,28.51,0,0,1,18.5,59Z"
                              transform="translate(-0.01 0)"/>
                        <path className="fill-current"
                              d="M40.8,42.9a17.64,17.64,0,0,1,21.9-7.5,3.55,3.55,0,0,0,3.8-1.8h0a3.71,3.71,0,0,0-1.3-5c-.2-.1-.5-.2-.7-.3a21.25,21.25,0,0,0-20.8,36,2.09,2.09,0,0,0,.6.4A17.48,17.48,0,0,1,40.8,42.9Z"
                              transform="translate(-0.01 0)"/>
                        <path className="fill-current"
                              d="M49.3,63.4h0a3.51,3.51,0,0,0-.7-4.5A14,14,0,0,1,62.3,35.2c.1,0,.3.1.4.1A17.6,17.6,0,0,0,44.3,64.7a3.56,3.56,0,0,0,5-1.3Z"
                              transform="translate(-0.01 0)"/>
                        <circle className="fill-current" cx="57.89" cy="48.5" r="6.9"/>
                        <path className="fill-current"
                              d="M62.3,35.2A17.64,17.64,0,0,0,43.9,64.4a16.8,16.8,0,0,1-3.6-4.8,19.56,19.56,0,0,1-1.8-5.8,15.21,15.21,0,0,1,.3-6A17.56,17.56,0,0,1,59.7,34.4,12.94,12.94,0,0,1,62.3,35.2Z"
                              transform="translate(-0.01 0)"/>
                    </g>
                </g>
        </svg>
    </div>
);

const App = ({ instance }) => {

    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Router>
                    <MsalProvider instance={instance}>
                        <PageLayout>
                            <Pages />
                        </PageLayout>
                    </MsalProvider>
                </Router>
            </Suspense>
        </ErrorBoundary>

    );
}

export default App;
