import React, {useEffect, useState} from "react";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import {protectedResources} from "../authConfig";
import UserClient from "../api/UserClient";
import {useAccount, useMsal} from "@azure/msal-react";
import Header from "../components/Header";

const Teams = (props) => {

    const {instance, accounts, inProgress} = useMsal();
    const account = useAccount(accounts[0] || {});
    const [teams, setTeams] = useState([]);

    useEffect(() => {

        if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent({
                scopes: protectedResources.apiHello.scopes,
                account: account
            }).then((response) => {

                const client = new UserClient(response.accessToken);
                client.fetchTeamsByUserId(response.uniqueId)
                    .then(response => {
                        setTeams(response?.data)
                    });

            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({
                            scopes: protectedResources.apiHello.scopes,
                        }).then((response) => {
                            const client = new UserClient(response.accessToken);
                            client.fetchTeamsByUserId(response.uniqueId)
                                .then(response => setTeams(response.data));
                        }).catch(error => console.log(error));
                    }
                }
            });
        }

    }, [account, inProgress, instance]);

    return(
        <>
            <Header title={props.title}/>
            <div className="flex-grow flex-shrink-0 p-4">
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-4 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                        {
                            teams?.map((team) => (
                                <div className="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
                                    <img
                                        className=" w-full h-96 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
                                        src="https://mdbootstrap.com/wp-content/uploads/2020/06/vertical.jpg" alt=""/>
                                    <div className="p-6 flex flex-col justify-start">
                                        <h5 className="text-gray-900 text-xl font-medium mb-2">{team.name}</h5>
                                        <p className="text-gray-700 text-base mb-4">
                                            {team.description}
                                        </p>
                                        <p className="text-gray-600 text-xs">Last updated 3 mins ago</p>

                                    </div>
                                    <div className="p-6 flex flex-col justify-end">
                                        <div className="space-x-2 px-4 py-3 text-right sm:px-6">
                                            <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-wbt-color hover:bg-wbt-color-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >Button</button>
                                        </div>
                                    </div>
                                </div>
                                ))
                        }

            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Teams;
