import {Formik, Field, Form, FormikHelpers, ErrorMessage, useFormikContext} from 'formik';
import {Trans} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {Datepicker} from "./Datepicker.tsx";
import TimezoneSelect from "react-timezone-select";
import './UserPreferences.css'
import {WorkingDay} from "../models/WorkingDay.ts";
import TextField from '@mui/material/TextField';
import moment from "moment";
import {IconButton} from "@mui/material";
import {AddAlarm, AlarmOff, PlusOneOutlined} from "@mui/icons-material";
import {WorkingHour} from "../models/WorkingHour.ts";


export const UserPreferences = (props) => {

    const [birthday, setBirthday] = useState(new Date());
    const [selectedTimezone, setSelectedTimezone] = useState({});
    const [workingDays, setWorkingDays] = useState([]);

    const sortWorkingDays = (newWorkingDays) => {
        setWorkingDays(newWorkingDays.sort((a, b) => a['dayOfWeek'] > b['dayOfWeek']  ? 1 : a['dayOfWeek'] === b['dayOfWeek'] ? 0 : -1));
    }

    const updateWeekday = (day, checked) => {
        const newWorkingDays = [...workingDays];
        let dayOfWeek = workingDays.find(i => i.dayOfWeek === day);
        if(!dayOfWeek && checked){
            dayOfWeek =  new WorkingDay();
            dayOfWeek.dayOfWeek = day;
            dayOfWeek.workingHour = new WorkingHour('08:00','17:00');
            newWorkingDays.push(dayOfWeek);
            sortWorkingDays(newWorkingDays);
        }
        if(dayOfWeek && !checked){
            setWorkingDays(workingDays.filter(i => !(i.dayOfWeek === day)));
        }
    }

    const addWorkingTime = (day) => {
        const newWorkingDays = [...workingDays];
        let dayOfWeek =  new WorkingDay();
        dayOfWeek.dayOfWeek = day;
        dayOfWeek.workingHour = new WorkingHour('08:00','17:00');
        newWorkingDays.push(dayOfWeek);
        sortWorkingDays(newWorkingDays);
    }

    const removeWorkingTime = (index, values) => {
        const newWorkingDays = [...workingDays];
        newWorkingDays.splice(index, 1);
        updateDaysChecked(values,newWorkingDays);
        sortWorkingDays(newWorkingDays);
    }

    const updateDaysChecked = (values,newWorkingDays) => {
        values.workingDay_Mo= false;
        values.workingDay_Tu= false;
        values.workingDay_We= false;
        values.workingDay_Th= false;
        values.workingDay_Fr= false;
        values.workingDay_Sa= false;
        values.workingDay_Su= false;

        newWorkingDays.forEach(element => {
            if(element.dayOfWeek == 1){
                values.workingDay_Mo= true;
            }
            if(element.dayOfWeek == 2){
                values.workingDay_Tu= true;
            }
            if(element.dayOfWeek == 3){
                values.workingDay_We= true;
            }
            if(element.dayOfWeek == 4){
                values.workingDay_Th= true;
            }
            if(element.dayOfWeek == 5){
                values.workingDay_Fr= true;
            }
            if(element.dayOfWeek == 6){
                values.workingDay_Sa= true;
            }
            if(element.dayOfWeek == 7){
                values.workingDay_Su= true;
            }
        })
    }

    return (
        <>
            <div>
                <div className="mt-8 max-w-7xl mx-auto">
                    <div className="shadow overflow-hidden sm:rounded-md">
                        <Formik
                            initialValues={{
                                workingDay_Mo: false,
                            }}
                            onSubmit={(
                                values: Values,
                                { setSubmitting }: FormikHelpers<Values>
                            ) => {
                                setTimeout(() => {
                                    alert(JSON.stringify(values, null, 2));
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {formik => (
                            <Form>

                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6">
                                            <label htmlFor="job-title" className="block text-sm font-medium text-gray-700">
                                                <Trans i18nKey="userPreferences.zoomUser"/>
                                            </label>
                                            <Field
                                                type="text"
                                                name="zoomUser"
                                                id="zoomUser"
                                                className="mt-1 focus:ring-wbt-color focus:border-wbt-color block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            <ErrorMessage className="error" component="div" name="zoomUser" />

                                        </div>

                                        <div className="col-span-6">
                                            <label htmlFor="job-title" className="block text-sm font-medium text-gray-700">
                                                <Trans i18nKey="userPreferences.birthday"/>
                                            </label>
                                            <Datepicker id="birthday" name="birthday" date={birthday} onChange={setBirthday}/>

                                            <ErrorMessage className="error" component="div" name="birthday" />

                                        </div>

                                        <div className="col-span-6">
                                            <label htmlFor="job-title" className="block text-sm font-medium text-gray-700">
                                                <Trans i18nKey="userPreferences.timezone"/>
                                            </label>
                                            <TimezoneSelect
                                                value={selectedTimezone}
                                                onChange={setSelectedTimezone}
                                                className="sm:text-sm focus:border-wbt-color"
                                            />

                                            <ErrorMessage className="error" component="div" name="timezone" />

                                        </div>
                                    </div>
                                </div>

                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <div className="col-span-6">
                                        <label htmlFor="job-title" className="block text-sm font-medium text-gray-700">
                                            <Trans i18nKey="userPreferences.workingDays"/>
                                        </label>

                                        <div className="weekDays-selector">
                                            <Field className="weekday" type="checkbox" name="workingDay_Mo" onChange={(event) => {updateWeekday(1,event.target.checked); formik.setFieldValue('workingDay_Mo', event.target.checked)}}
                                                   id="weekday-mon"/><label
                                            htmlFor="weekday-mon">M</label>
                                            <Field className="weekday" type="checkbox" name="workingDay_Tu" onChange={(event) => {updateWeekday(2,event.target.checked); formik.setFieldValue('workingDay_Tu', event.target.checked)}}
                                                   id="weekday-tue" /><label
                                            htmlFor="weekday-tue">T</label>
                                            <Field className="weekday" type="checkbox" name="workingDay_We" onChange={(event) => {updateWeekday(3,event.target.checked); formik.setFieldValue('workingDay_We', event.target.checked)}}
                                                   id="weekday-wed" /><label
                                            htmlFor="weekday-wed">W</label>
                                            <Field className="weekday" type="checkbox" name="workingDay_Th" onChange={(event) => {updateWeekday(4,event.target.checked); formik.setFieldValue('workingDay_Th', event.target.checked)}}
                                                   id="weekday-thu" /><label
                                            htmlFor="weekday-thu">T</label>
                                            <Field className="weekday" type="checkbox" name="workingDay_Fr" onChange={(event) => {updateWeekday(5,event.target.checked); formik.setFieldValue('workingDay_Fr', event.target.checked)}}
                                                   id="weekday-fri" /><label
                                            htmlFor="weekday-fri">F</label>
                                            <Field className="weekday" type="checkbox" name="workingDay_Sa" onChange={(event) => {updateWeekday(6,event.target.checked); formik.setFieldValue('workingDay_Sa', event.target.checked)}}
                                                   id="weekday-sat" /><label
                                            htmlFor="weekday-sat">S</label>
                                            <Field className="weekday" type="checkbox" name="workingDay_Su" onChange={(event) => {updateWeekday(7,event.target.checked); formik.setFieldValue('workingDay_Su', event.target.checked)}}
                                                   id="weekday-sun" /><label
                                            htmlFor="weekday-sun">S</label>
                                        </div>
                                    </div>
                                </div>

                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                        {workingDays.map((day, index) => {
                                            return (

<>
                                                <div className="col-span-1">
                                                        <TextField
                                                            id={"startTime_"+day+"_"+index}
                                                            label={moment().day(day.dayOfWeek).format('dddd') + " from"}
                                                            type="time"
                                                            variant="standard"
                                                            defaultValue={day.workingHour.startTime}
                                                            onChange={formik.handleChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                step: 900, // 15 min
                                                            }}
                                                            sx={{ width: 150 }}
                                                        />
                                                </div>

                                            <div className="col-span-1">
                                                <TextField
                                                    id={"endTime_"+day+"_"+index}
                                                    label={moment().day(day.dayOfWeek).format('dddd') + " to"}
                                                    type="time"
                                                    variant="standard"
                                                    defaultValue={day.workingHour.endTime}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 900, // 15 min
                                                    }}
                                                    sx={{ width: 150 }}
                                                />
                                            </div>
                                                <div className="col-span-1 flex items-end">
                                                    <div className="grid grid-cols-2 gap-6">
                                                        <div className="col-span-1 flex items-end">
                                                            <IconButton
                                                                onClick={() => addWorkingTime(day.dayOfWeek)}
                                                            >
                                                                <AddAlarm />
                                                            </IconButton>
                                                        </div>

                                                        <div className="col-span-1 flex items-end">
                                                            <IconButton aria-label="delete"
                                                            onClick={() => {removeWorkingTime(index, formik.values);}}>
                                                                <AlarmOff />
                                                            </IconButton>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="col-span-3">
                                                </div>
</>
                                            );
                                        })}
                                    </div>
                                    </div>


                                <div className="space-x-2 px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-wbt-color hover:bg-wbt-color-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Submit</button>
                                </div>


                            </Form>
                                )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
}
