import {useEffect, useState} from "react";

import {MsalAuthenticationTemplate, useAccount, useMsal} from "@azure/msal-react";
import {InteractionRequiredAuthError, InteractionType} from "@azure/msal-browser";

import {loginRequest, protectedResources} from "../authConfig";
import {HelloData} from "../components/DataDisplay";
import { callMsGraph } from "../graph";

const HelloContent = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [helloData, setHelloData] = useState(null);

    useEffect(() => {
        if (account && inProgress === "none" && !helloData) {
            instance.acquireTokenSilent({
                scopes: protectedResources.apiHello.scopes,
                account: account
            }).then((response) => {

                callMsGraph(response.accessToken).then(response => setHelloData(response));

            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({
                            scopes: protectedResources.apiHello.scopes,
                        }).then((response) => {
                            callMsGraph(response.accessToken).then(response => setHelloData(response));
                        }).catch(error => console.log(error));
                    }
                }
            });
        }
    }, [helloData, account, inProgress, instance]);

    return (
        <>
            { helloData ? <HelloData graphData={helloData} /> : null }
        </>
    );
};


/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const Hello = () => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            <HelloContent />


        </MsalAuthenticationTemplate>


    )
};
